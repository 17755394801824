* {
  color: #804b83;
}

a {
  color: grey;
  font-weight: bold;
  text-decoration: none;
}

a:hover {
  color: lightgrey;
}

/* .circular_image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  background-color: white;
  margin: 0 auto;
  margin-top: -20px;
  display: inline-block;
  vertical-align: middle;
} */

/* .circular_image img {
  width: 100%;
} */

