@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

/* @media screen and (min-width: 960px) { */
/* html {
      margin-left: calc(100vw - 100%);
      margin-right: 0;
      overflow: auto;
  } */

html {
  overflow-y: scroll;
}

.logo-blue {
  color: #00c2bb;
}

/* used for page banner cards */
.circular_image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  background-color: white;
  margin: 0 auto;
  margin-top: -50px;
  display: inline-block;
  vertical-align: middle;
}
.circular_image img {
  width: 100%;
}
.bg-card-semi-opaque {
  background-color: rgba(225, 255, 254, 0.675);
}

.container-bg {
  background-image: url("./images/cube-bg.svg");
  background-repeat: no-repeat;
}

.react-markDown p {
  text-align: center;
}

.react-markDown a {
  font-weight: bold;
  text-decoration: none;
}

.react-markDown-preview p {
  text-align: center;
}

.alpha {
  color: #00c2bb;
}

.beta {
  color: #804b83;
}

.nav-link {
  cursor: pointer;
}

.modal-box {
  width: 100%;
}

/*
  * The Modal (background)
  */

@media (min-width: 640px) {
  .modal-box {
    max-width: 22rem;
  }
}

.modal {
  width: clamp(75%, 198px, 90%);
  background-color: #11111101;
  margin: auto;
  padding: 0 1rem;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
