


/* .group:hover .group-hover\:scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  } */
  
  /* .group:hover .group-hover\:border-b {
    border-bottom-width: 1px;
  } */
  
  /* .group:hover .group-hover\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgb(239 246 255 / var(--tw-border-opacity));
  } */
  
  /* hamburger menu */

  .hamburger {
    cursor: pointer;
    width: 24px;
    height: 24px;
    transition: all 0.25s ease-in-out;
    position: relative;
  }

  .hamburger-top,
  .hamburger-middle,
  .hamburger-bottom {
    position: absolute;
    width: 24px;
    height: 2px;
    top: 0;
    left: 0;
    background: #000;
    transform: rotate(0);
    transition: all 0.5s ease-in-out;
  }

  .hamburger-middle {
    transform: translateY(7px);
  }

  .hamburger-bottom {
    transform: translateY(14px);
  }

  .open {
    transform: rotate(90deg);
  }

  .open .hamburger-top {
    transform: rotate(45deg) translateY(6px) translateX(6px);
  }

  .open .hamburger-middle {
    display: none;
  }

  .open .hamburger-bottom {
    transform: rotate(-45deg) translateY(6px) translateX(-6px);
  }
