.node-link {
    font-size: 90%;
}

.node-sm {
    font-size: 120%;
}

.node-md {
    font-size: 150%;
}

.node-lg {
    font-size: 180%;
}

.node-xl {
    font-size: 200%;
}

/*
--tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
--tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
*/
